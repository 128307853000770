<template>
    <div class="container-image">
        <div class="content-image">
            <img :src="bannerUrl" alt="Missão Modatta" />
            <div class="container-time">
                <div class="reward-container">
                    <span class="reward">
                    <img src="../../../../public/svg/clock.svg" alt="clock" />
                    Recompensa {{ paymentDays === 0 ? 'Imediata' : `Em ${paymentDays} dias` }}
                </span>
                </div>
                <div class="container-time-rest-section">
                    <span class="title-time">Oferta acaba em</span>
                    <div class="container-time-rest">
                        <div class="time">
                            <span class="value">{{ time.days }}</span>
                            <span class="description">Dias</span>
                        </div>
                        <span class="division">:</span>
                        <div class="time">
                            <span class="value">{{ time.hours }}</span>
                            <span class="description">Horas</span>
                        </div>
                        <span class="division">:</span>
                        <div class="time">
                            <span class="value">{{ time.minutes }}</span>
                            <span class="description">Min</span>
                        </div>
                        <span class="division">:</span>
                        <div class="time">
                            <span class="value">{{ time.seconds }}</span>
                            <span class="description">Seg</span>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="container-time-available">
                    <div class="row-center">
                        <img src="../../../../public/svg/info.svg" alt="info" />
                        <span class="title-time">Disponivel</span>
                    </div>
                    <div class="container-time-rest">

                    <div class="available">
                            <span class="value">{{ availables }}</span>
                            <span class="description">Ofertas</span>
                        </div>
                    </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentImage",
    props: {
        bannerUrl: {
            type: String,
        },
        validationMaxHours: {
            type: String,
        },
        paymentDays: {
            type: Number
        },
        availables: {
            type: [Number, String]
        }
    },
    data() {
        return {
            time: {
                days: 0, // start from 10 days
                hours: 0, // start from 24 hours
                minutes: 0, // start from 60 minutes
                seconds: 0 // start from 60 seconds
            },
            intervalId: null
        };
    },
    methods: {
            convertHoursToTime(hoursString) {
                const totalHours = parseFloat(
                    hoursString
                );
                const days = Math.floor(totalHours / 24);
                const hours = Math.floor(totalHours % 24);
                const minutes = Math.floor((totalHours * 60) % 60);
                const seconds = Math.floor((totalHours * 3600) % 60);

                return {
                    days,
                    hours,
                    minutes,
                    seconds
                };
            },
        },
    async mounted() {
        this.time = this.convertHoursToTime(this.validationMaxHours)
        this.intervalId = setInterval(() => {
            this.time.seconds--;
            if (this.time.seconds <= 0) {
                this.time.minutes--;
                this.time.seconds = 59;
            }
            if (this.time.minutes <= 0) {
                this.time.hours--;
                this.time.minutes = 59;
            }
            if (this.time.hours <= 0 && this.time.days > 0) {
                this.time.days--;
                this.time.hours = 23;
              }

        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    }
};
</script>

<style lang="scss" scoped>

.divider {
    height: 42px;
    width: 2px;
    position: relative;
    top: 30%;
    margin: 0 25px;
    background-color: #F3F4F5;
}

.content-image {
    width: 80%;
    display:flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    height: 50vh;
}

.container-time-rest-section {
    display:flex;
    width:60%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reward-container {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 0;
    top: -14px;
}

.reward {
    background-color: #FFCD46;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    align-content: center;
    min-width:240px;
    padding: 4px 0;
    text-align: center;
    border-radius: 30px;
    color: var(--grey-blues-dark-blue, #0A1735);
    font-family: 'Silka Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 27.289px; /* 170.557% */

    &> img {
        margin-right: 8px;
        width:22px;
        height:22px;
        position: relative;
        top:-1px;
    }
}

.division {
    color: var(--Black, #101010);
    font-family: Montserrat;
    font-size: 18.351px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.351px; /* 100% */
    position: relative;
    top: -12px;
}

.container-time-rest {
    display:flex;
    margin-top:16px;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.available {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;

    .value {
        background-color: #F3F4F5;
        padding: 16px;
        min-width:60px;
        width: 100%;
        text-align:center;
        font-weight: bold;
        border-radius:8px;
    }


    .description {
        margin-top:8px;
        color: var(--Black, #101010);
        font-family: 'Silka Regular';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        text-align: left !important;
    }
}

.title-time {
    color: #101010;
    font-family: 'Silka Medium';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    opacity: 0.7;
}

.time {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .value {
        background-color: #F3F4F5;
        padding: 16px 8px;
        min-width:45px;
        font-weight: bold;
        border-radius:8px;
        text-align:center;
    }

    .description {
        margin-top:8px;
        color: var(--Black, #101010);
        font-family: 'Silka Regular';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
    }
}

.container-time-available{
    display:flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    align-content:center;
    width:50%;
}
.container-image {
    max-height: 50vh;
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & > div {
        flex:1;
        position: relative;

        & > img {
            border-radius:24px;
            width: 100%;
            height: 100%;
        }
    }

    .container-time {
        width: 50%;
        min-width: 400px;
        background-color:white;
        position: relative;
        display:flex;
        bottom:110px;
        flex-direction: row;
        padding:16px 32px;
        padding-top:60px;
        filter: drop-shadow(0px 2px 30px rgba(0, 0, 0, 0.05));
        border-radius:24px;
        height: 172px;
    }
}

    .row-center{
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        & > img {
            width:18px;
            height: 18px;
            margin-right: 4px;
        }

    }

    @media only screen and (max-width: 768px) {


        .content-image {
            width:100%;
            padding-bottom:20px;

        }
        .reward-container {
            top:-30px;

        }
        .divider {
            height: 22px;
            width: 2px;
            position:absolute;
            left:20%;
            top: 40%;
            background-color: #F3F4F5;
        }

        .container-image {
            max-height: none;


         & > div {
            display:flex;
            justify-content: flex-start;
            & > img {
                border-radius:24px 24px 0 0;
                width: 90%;
                height: 60%;
            }
        }

        .container-time {
            top: 10px;
            bottom: 200px;
            width:100%;
            padding:16px 20px;
            border-radius:16px;
            height: 134px;
            min-width: 240px;

        }
    }

    .row-center{
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 32%;

        & > img {
            width:12px;
            height: 12px;
            margin-right: 4px;
        }

    }

    .container-time-rest {
        .available {
            align-items: flex-end;
        }

    }

    .time {

    .value {
        padding: 5px;
        font-size:16px;
    }



    .description {
        margin-top:4px;
        font-family: 'Silka Regular';
        font-size: 10px;
    }
    }

    .title-time {
    font-family: 'Silka Medium';
    font-size: 10px;
    opacity: 0.7;
}

.available {

    .value {
        background-color: #F3F4F5;
        padding: 5px 10px;
        width: 60%;
        font-size:16px;
    }


    .description {
        margin-top:4px;
        margin-right: 18%;
        color: var(--Black, #101010);
        font-size: 10px;
    }
}


    }
</style>
