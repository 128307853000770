<template>
    <div class="step-image-container">
        <span class="title">Saiba como ({{ this.index + 1 }}/{{ this.stepLength }})</span>
        <span class="subtitle">{{step.description}}</span>
        <div>
            <img :src="step.media_source" />
        </div>
    </div>
</template>

<script>
export default {
    name: "StepImage",
    props: {
        index: Number,
        stepLength: Number,
        step: Object,
    },
};
</script>

<style lang="scss" scoped>
    .step-image-container {
        display:flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 100%;


        div {
            border-radius:20px;
            width: 50%;
            height: 80%;
            display: flex;
            justify-content: center;

            img {
                object-fit: fill;
                width:80%;
                height: 100%;
            }
        }
        
       
        span {
            text-align: center;
        }

        .title {
            color: var(--Black, #101010);
            text-align: center;
            font-family: 'Silka Medium';
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 44.8px */
        }

        .subtitle {
            color: var(--Black, #101010);
            text-align: center;
            width:50%;
            font-family: 'Silka Medium';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
        }
    }

@media only screen and (max-width: 768px) {
    .step-image-container {
        height: 45vh;
        
        div {
            border-radius:20px;
            width:80%;
            height: 100%;
            display: flex;
            justify-content: center;

            img {
                object-fit: fill;
                width:80%;
                height: 100%;
            }
        }

        .title {
            color: var(--Black, #101010);
            text-align: center;
            font-family: 'Silka Medium';
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 44.8px */
        }

        .subtitle {
            color: var(--Black, #101010);
            text-align: center;
            width:70%;
            font-family: 'Silka Medium';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
        }
    }

}
</style>