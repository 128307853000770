<template>
  <div class="radio-answer-container" v-show="this.questionData.element_type_id === this.radioTypeSurveyElementId">
      <span class="radio-question">{{ this.questionData.question }}</span>
      <div
        v-for="(answerData, index) in this.questionData.offer_answers"
        :class="{'radio-answer-item': true, 'radio-answer-item-active': index === answerActiveIndex}"
        :key="index"
        @click="handleSelectAnswer(answerData, index)"
        >
        <span class="radio-answer-label">{{ answerData.answer }}</span>
        <input class="radio-answer-value" type="radio">
      </div>
  </div>
</template>

<script>
  export default {
    props: {
      questionData: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        radioTypeSurveyElementId: 'ELT2',
        answerActiveIndex: null,
        answerActive: {}
      };
    },
    methods: {
      handleSelectAnswer(selectedAnswer, selectedAnswerIndex) {
        this.answerActive = selectedAnswer;
        this.answerActiveIndex = selectedAnswerIndex;
        this.$emit('radio-answer-question', {
          question_id: `QUE${this.questionData.id}`,
          answer_id: `ANS${selectedAnswer.id}`,
          answer: selectedAnswer.answer
        });
      }
    },
  };
</script>


<style lang="scss" scoped>
  .radio-answer-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .radio-question {
      font-family: "Silka Medium", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      margin-bottom: 4%;
      color: #101010;
    }

    .radio-answer {
      width: 100%;
      height: 36vh !important;
      border: 1px solid #E0E0E0;
      border-radius: 8px;
      background-color: #F9FAFB;
      font-size: 16px !important;
      padding: 2% 2%;
      resize: none !important;
    }

    .radio-answer-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      background-color: #F3F4F5;
      border: 1px solid #D9D9D9;
      padding: 18px 12px;
      margin-bottom: 12px;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        .radio-answer-value {
          box-shadow: 0 0 0 6px #19D2B433;
        }
      }

      .radio-answer-label {
        font-family: "Silka Medium", sans-serif;
        font-size: 16px;
        line-height: 18px;
        color: #101010;
      }

      .radio-answer-value {
        border-radius: 50%;
        border: 1px solid #D7D7D7;
        width: 18px;
        height: 18px;
      }
    }

    .radio-answer-item-active {
      background-color: #FFFFFF;
      border: 1px solid #19D2B4;

      .radio-answer-value {
        background-color: #19D2B4;
        border: 4px solid #19D2B433;
      }
    }
  }

</style>
