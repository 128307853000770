<template>
  <div class="long-answer-container" v-show="this.questionData.element_type_id === this.longTypeSurveyElementId">
      <span class="long-question">{{ this.questionData.question }}</span>
      <textarea class="long-answer" type="text" v-model="userAnswer"></textarea>
  </div>
</template>

<script>
  export default {
    props: {
      questionData: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        longTypeSurveyElementId: 'ELT12',
        userAnswer: '',
      };
    },
    watch: {
      userAnswer(value) {
        if (value) {
          this.$emit('long-answer-question', {
            question_id: `QUE${this.questionData.id}`,
            answer_id: null,
            answer: value
          });
        } else {
          this.$emit('long-answer-question', null);
        }
      }
    }
  };
</script>


<style lang="scss" scoped>
  .long-answer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .long-question {
    font-family: "Silka Medium", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-bottom: 4%;
    color: #101010;
  }

  .long-answer {
    width: 100%;
    height: 36vh !important;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    background-color: #F9FAFB;
    font-size: 16px !important;
    padding: 2% 2%;
    resize: none !important;
  }
</style>
