<template>
  <div class="body">
    <Header></Header>
    <div v-if="offer">
      <div v-for="(item, index) in offer.steps" :key="index">
        <StepImage
          v-if="step == index + 1 && item.media_type_id == 'MTY2'"
          :step="item"
          :index="index"
          :stepLength="offer.steps.length"
        />
        <StepVideo
          v-else-if="
            step == index + 1 && ['MTY3', 'MTY4'].includes(item.media_type_id)
          "
          :step="item"
          :index="index"
          :stepLength="offer.steps.length"
          :setVideoEnded="setVideoEnded"
          :setSecondsRemainingVideo="setRemainingTime"
        />
        <StepText
          v-else-if="step == index + 1 && (item.media_type_id === null || item.media_type_id === 'MTY1')"
          :step="item"
          :index="index"
          :stepLength="offer.steps.length"
        />
      </div>
    </div>
    <StepButtons
      :steps="offer.steps"
      :stepLength="offer.steps.length"
      :actualMediaTypeId="actualMediaTypeId"
      :secondsRemainingVideo="secondsRemainingVideo"
      :step="step"
      :videoEnded="videoEnded"
      :incrementStep="incrementStep"
    />
    <Footer></Footer>
  </div>
</template>

<script>
import { getOffer } from "../../api/offer";

export default {
  data() {
    return {
      step: 1,
      videoEnded: false,
      secondsRemainingVideo: 0,
      actualMediaTypeId: "",
      offer: {
        id: "",
        name: "",
        description: "",
        action: "",
        country_id: "",
        currency_id: "",
        validation_max_hours: "",
        max_accepts: "",
        unit_value: "",
        perc_value: "",
        interaction_type_id: "",
        type_label_id: "",
        interaction_validation_id: "",
        banner: "",
        brand_id: "",
        recommendations_count: "",
        decisions_count: "",
        banner_url: "",
        brand: {
          id: "",
          name: "",
          brand_logo: "",
          company_url: "",
          logo_url: "",
        },
        steps: [
          {
            id: "",
            offer_id: "",
            description: "",
            index: "",
            created_at: "",
            updated_at: "",
          },
        ],
      },
    };
  },
  async mounted() {
    try {
      const response = await getOffer(this.offerId);
      const offer = response.data;
      offer.steps = offer.steps.sort(
        (a, b) => parseInt(a.index) - parseInt(b.index)
      );
      this.offer = offer;
      this.actualMediaTypeId = this.offer.steps[0].media_type_id;
    } catch (error) {
      this.$router.push(`/m/error`);
    }
  },
  watch: {
    step(newStep) {
      if (newStep > this.offer.steps.length) {
        this.$router.push(`/m/start/${this.offerId}`);
      } else {
        this.secondsRemainingVideo = 0
        this.videoEnded = false;
        this.actualMediaTypeId = this.offer.steps[newStep - 1].media_type_id;
      }
    },
  },
  props: {
    offerId: {
      type: String,
      required: true,
    },
  },
  methods: {
    incrementStep() {
      this.step++;
    },
    setRemainingTime(secondsRemaining) {
      this.secondsRemainingVideo = secondsRemaining;
    },
    setVideoEnded(value) {
      this.videoEnded = !!value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .body {
    background-color: #fff;
    flex: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
</style>
