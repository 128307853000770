    <template>
    <div class="container-what-modatta">
        <h2 class="title-mobile">O que é a Modatta</h2>
        <div class="layer-1">
        <div class="layer-2">
            <div class="layer-3">
           <img src="../../../../public/img/hip-hop-girl.png" />
            </div>
        </div>
        </div>
        <div class="content">
            <h2>O que é a Modatta</h2>
            <span class="content-text">A Modatta tem como missão permitir que todos, tanto empresas como consumidores, se beneficiem do valor gerado através de dados pessoais, promovendo a privacidade, transparência e um compartilhamento justo do valor.</span>
            <span class="content-text">Atualmente, a maior parte do valor gerado por nossos dados pessoais é entregue às gigantes da tecnologia que ficam no caminho entre uma empresa e seus clientes em potencial, agindo como intermediários, controlando tanto a demanda quanto a oferta.</span>
                <div class="container-buttons">
                    <a class="primary-button"  @click="onClick">
                        Quero começar
                    </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentWhatModatta",
    data() {
      return {
        googleLink: '',
        appleLink: ''
      }
    },
    props: {
      onClick: {
        type: Function,
        required: true,
      },
      offerId: {
        type: String,
        required: true
      }
    },
    mounted() {
      this.googleLink = process.env.VUE_APP_GOOGLE_STORE
      this.appleLink = process.env.VUE_APP_APPLE_STORE
    }
};
</script>

<style lang="scss" scoped>
    .title-mobile {
        display:none;
    }

    a, a:visited, a:hover, a:active {
  color: inherit;
}

    .content {
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        width: 40%;
        margin-left: 88px;

        span.content-text {
            width: 60%;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: -0.2px;
            margin-top:30px;
        }
    }



    .container-buttons{
        display:flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 32px;
        width:50%;
        align-content: center;

        span {
            margin-left: 16px;
            font-weight: bold !important;
            line-height: 16.119px; /* 133.333% */
        }
    }

    .primary-button {
            border-radius: 12px;
            background: var(--Green, #19D2B4);
            width:100%;
            padding:16px;
            text-align: center;
            margin: 0px;
            font-weight: bold !important;
            line-height: 16.119px; /* 133.333% */
            color:black;
}

    .button-store {
        display:flex;
        cursor: pointer;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        border-radius: 6.448px;
        background: #FFF;
        box-shadow: 0px 10.02469px 75.18518px 0px rgba(0, 0, 0, 0.08);
        padding: 16px 16px;
        width:45%;
        height: 80px;
    }


    .container-what-modatta {
        background-color:#FFF;
        padding-top: 120px;
        display:flex;
        justify-content: center;
        padding-bottom:100px;



        .layer-1 {
            margin-left:200px;
            display:flex;
            justify-content: center;
            align-items: center;
            border-radius: 200px;
            background-color: rgba(255, 205, 70, 0.2);
            width: 401px;
            height: 401px;

        }

        .layer-2 {
            display:flex;
            justify-content: center;
            align-items: center;
            border-radius: 200px;
            background-color: rgba(255, 205, 70, 0.5);
            width: 343px;
            height: 343px;
            overflow-x: hidden;
        }

        .layer-3 {
            display:flex;
            justify-content: center;
            align-items: center;
            border-radius: 200px;
            background-color: rgba(255, 205, 70, 1);
            width: 290px;
            height: 290px;

            img {
            width: 210px;
            height: 340px;
        }
        }



    }

    @media only screen and (max-width: 768px) {

        .container-what-modatta {
            padding-top: 60px;
            padding-bottom:30px;
        }

        .content-text {
            display:block;
        }

        .title-mobile {
            display:block;
            margin-bottom: 34px !important;
        }

.container-what-modatta {
    background-color:#FFF;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;


        .layer-1 {
            margin-left:0px;
            width: 351px;
            height: 351px;

        }

        .layer-2 {
            width: 303px;
            height: 303px;
        }

        .layer-3 {
            width: 260px;
            height: 260px;

            img {
            width: 190px;
            height: 301px;
        }
    }

}

.container-buttons {
    display:none;
}

    .content {
        width:90%;
        span.content-text {
            width: 100%;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: -0.2px;
            margin-top:30px;
        }

        & > h2 {
            display:none;
        }

        .button-store {
            display:none;
        }
        margin:0;
    }
}

</style>
