<template>
  <div class="body">
    <Header></Header>
    <Loading v-show="isLoading"></Loading>
    <PrivateBrowser v-show="isBrowserPrivate" :on-copy-link="copyLinkDestiny" :on-talk-to-us="() => this.goToTalkToUs('3001')"></PrivateBrowser>
    <Error v-show="isError" :on-talk-to-us="() => this.goToTalkToUs('3002')"></Error>
    <Footer></Footer>
  </div>
</template>

<script>
  import Loading from './components/Loading.vue';
  import PrivateBrowser from './components/PrivateBrowser.vue';
  import Error from './components/Error.vue';
  import { detectIncognito } from "detectincognitojs";
  import Bugsnag from '@bugsnag/js'
  import { sendEventTargetMission } from '../../api/analytics';

  export default {
    name: "MissionRedirect",
    components: { Loading, PrivateBrowser, Error },
    data() {
      return {
        isLoading: false,
        isBrowserPrivate: false,
        isError: false,
        urlTargetMission: process.env.VUE_APP_WHATSAPP_SUPORT,
      }
    },
    async mounted() {
      try {
        this.isLoading = true;
        const sourceApp = this.getParamsToSource(this.$route.fullPath);
        await this.sendTrackEvent(sourceApp);
        this.urlTargetMission = sourceApp.offer_url;
        const browser = await detectIncognito();
        if (browser.isPrivate) {
          this.goToPrivateBrowser();
        } else {
          this.goToDestiny(this.urlTargetMission);
        }
      } catch (error) {
        this.goToError(error);
      }
    },
    methods: {
      async copyLinkDestiny() {
        try {
          await navigator.clipboard.writeText(this.urlTargetMission)
          this.$buefy.dialog.alert({
            title: "OK!",
            message: "Link copiado com sucesso!",
            confirmText: "Ok",
          });
        } catch (error) {
          this.$buefy.dialog.alert({
            title: "Ops, erro ao copiar o link!",
            message: "Desculpe! Mas vou precisar encaminhar você para o nosso suporte.",
            confirmText: "Ok",
            onConfirm: () => {
              this.goToDestiny(this.urlTargetMission);
            },
          });
          Bugsnag.notify(error);
        }
      },
      goToDestiny(urlTargetMission) {
        window.location.replace(urlTargetMission);
      },
      getParamsToSource(params) {
        return {
          offer_id: params.split('&modurl=')[0].split('?offerid=')[1],
          offer_url: decodeURIComponent(params.split('&modurl=')[1])
        }
      },
      async sendTrackEvent(sourceApp) {
        const urlParams = new URLSearchParams(sourceApp.offer_url);
        const modid =  urlParams.get("modid");

        await sendEventTargetMission({
          timestamp: Date.now(),
          user_id: null,
          device: {
            os_name: null,
          },
          event_type: "PREOFFER_REDIRECT",
          resource_type: "USERAGENT_INFO",
          app_page: "None",
          username: null,
          push_tk: null,
          app_version: null,
          resource: {
            offer_id: sourceApp.offer_id,
            offer_url: sourceApp.offer_url,
            modid: modid,
            user_agent: navigator.userAgent,
            private_browser: this.isBrowserPrivate,
            third_party_cookies_enabled: null,
          },
        });
      },
      goToTalkToUs(code) {
        this.goToDestiny(`${process.env.VUE_APP_WHATSAPP_SUPORT}&text=${encodeURIComponent(`Oi! Será que você poderia me ajudar com o erro ${code}?`)}`)
      },
      goToError(error) {
        if (Bugsnag.isStarted()) {
          Bugsnag.notify(error);
        }
        this.isLoading = false;
        this.isBrowserPrivate = false;
        this.isError = true;
      },
      goToPrivateBrowser() {
        this.isLoading = false;
        this.isError = false;
        this.isBrowserPrivate = true;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .body {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
  }
</style>
