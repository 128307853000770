<template>
  <div class="short-answer-container" v-show="this.questionData.element_type_id === this.shortTypeSurveyElementId">
      <span class="short-question">{{ this.questionData.question }}</span>
      <input class="short-answer" type="text" v-model="userAnswer" />
  </div>
</template>

<script>
  export default {
    props: {
      questionData: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        shortTypeSurveyElementId: 'ELT6',
        userAnswer: '',
      };
    },
    watch: {
      userAnswer(value) {
        if (value) {
          this.$emit('short-answer-question', {
            question_id: `QUE${this.questionData.id}`,
            answer_id: null,
            answer: value
          });
        } else {
          this.$emit('short-answer-question', null);
        }
      }
    }
  };
</script>


<style lang="scss" scoped>
  .short-answer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .short-question {
    font-family: "Silka Medium", sans-serif !important;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    margin-bottom: 4%;
    color: #101010;
  }

  .short-answer {
    width: 100%;
    height: 60px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    background-color: #F9FAFB;
    font-size: 16px !important;
    padding-left: 4%;
  }
</style>
