<template>
  <div class="container">
    <span class="title">Ops! Deu algo errado aqui!</span>
    <span class="subtitle">Entre em contato com o nosso atendimento e nos ajude a identificar o que houve.</span>
    <div class="button-custom" @click="onTalkToUs">Falar conosco</div>
  </div>
</template>

<script>
  export default {
    name: "Error",
    props: {
      onTalkToUs: Function
    },
  };
</script>

<style lang="scss" scoped>
  .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 70%;

      .title {
        color: var(--Black, #101010);
        font-family: 'Silka Medium';
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.2px;
        line-height: 140%;
        margin-bottom: 2%;
      }

      .subtitle {
        color: var(--Black, #101010);
        text-align: center;
        font-family: 'Silka Medium';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.2px;
        line-height: 140%;
        margin-top: 2%;
      }

      .button-custom {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-bottom: 2%;
        border: 1px solid var(--Light-grey-surface, #F7F7F7);
        background: var(--Primary, #30D6BB);
        border-radius: 12px;
        width: 40%;
        height: 40px;
        cursor: pointer;
      }
  }

@media only screen and (max-width: 768px) {
  .container {
    width: 92%;
      .title {
        font-size: 28px;
      }

      .subtitle {
        font-size: 16px;
      }

      .button-custom {
        width: 80%;
      }
  }
}
</style>
