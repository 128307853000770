<template>
    <div class="body">
        <Header/>
        <div class="step-error-container">
            <span class="title">Ops!</span>
            <span class="subtitle">An error happened, try again later</span>
        </div>
        <Footer/>
    </div>
</template>

<script>

export default {
}
</script>


<style lang="scss">
    .body {
        flex:1;
        height: 100vh;
        display:flex;
        flex-direction: column;
        width: 100%;
    }

    .modatta-button {
        margin-top: 40px !important;
    }

    .mission-input {
        margin-bottom: 16px !important;
        width: 100% !important;
    }

    label {
        margin-bottom: 8px;
        display:inline-block;
        color: var(--Black, #101010);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Silka Medium';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px; /* 178.571% */
        letter-spacing: 0.3px;

    }

    .vue-tel-input, .mission-input {
        border-radius: 8px !important;
        border: 1px solid var(--Medium-grey, #E0E0E0) !important;
        background: #F9FAFB !important;
    }

    .step-error-container {
        display:flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 70%;

        img {
            border-radius:20px;
            width: 50%;
            height: 80%;
        }

        span {
            text-align: center;
        }

        .title {
            color: var(--Black, #101010);
            text-align: center;
            font-family: 'Silka Medium';
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 44.8px */
        }

        .subtitle {
            color: var(--Black, #101010);
            text-align: center;
            width:50%;
            font-family: 'Silka Medium';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
        }
    }

@media only screen and (max-width: 768px) {
    .step-error-container {

        img {
            border-radius:20px;
            width:80%;
            height: 100%;
        }

        .title {
            color: var(--Black, #101010);
            text-align: center;
            font-family: 'Silka Medium';
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 44.8px */
        }

        .subtitle {
            color: var(--Black, #101010);
            text-align: center;
            width:70%;
            font-family: 'Silka Medium';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
        }
    }

}
</style>
