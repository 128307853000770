<template>
    <div class="step-text-container">
        <span class="title">Saiba como ({{ this.index + 1 }}/{{ this.stepLength }})</span>
        <div class="align-center">
            <span class="subtitle">{{step.description}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "StepText",
    props: {
        index: Number,
        stepLength: Number,
        step: Object,
    },
};
</script>

<style lang="scss" scoped>
    .step-text-container {
        display:flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 50vh;

        img {
            border-radius:20px;
            width: 50%;
            height: 80%;
        }
        
        span {
            text-align: center;
        }

        .align-center {
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .title {
            color: var(--Black, #101010);
            text-align: center;
            font-family: 'Silka Medium';
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 44.8px */
        }

        .subtitle {
            margin-top:40px;
            color: var(--Black, #101010);
            text-align: center;
            width:50%;
            font-family: 'Silka Medium';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
        }
    }

@media only screen and (max-width: 768px) {
    .step-text-container {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 45vh;
        
        img {
            border-radius:20px;
            width:80%;
            height: 100%;
        }

        .title {
            color: var(--Black, #101010);
            text-align: center;
            font-family: 'Silka Medium';
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 44.8px */
        }

        .subtitle {
            color: var(--Black, #101010);
            text-align: center;
            width:70%;
            font-family: 'Silka Medium';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
        }
    }

}
</style>