<template>
    <div class="container-footer">
        <div class="content-footer">
            <div class="content-links">
            <a target="_blank" href="https://www.modatta.com/about">O que é a Modatta</a>
            <div>
                <a target="_blank" href="https://www.modatta.com/legal">Advertência e Condições</a>
                <span>© {{ currentYear }} Modatta</span>

            </div>
            <a target="_blank" href="https://www.modatta.com/legal">Politicas de Privacidade</a>
        </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "Footer",
    computed: {
      currentYear() {
        return new Date().getFullYear();
      }
    }
  };
</script>

<style lang="scss" >
    .container-footer {
      background-color:#FFF;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .content-footer {
        display:flex;
        justify-content: center;
        padding: 36px 133px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding-top:50px;
        padding-bottom:90px;
        width:80%;

        .content-links {
            display:flex;
            flex-direction:row;
            align-items: center;
            justify-content: space-between;
            width: 55%;

            & > div {
            display:flex;
            flex-direction: column;
            align-items: center;
            position:relative;

            span {
                position:absolute;
                bottom: -50px;
            }
        }
        }



        a {
            color: var(--Black, #101010);
            text-align: center;
            font-family: 'Silka Medium';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.2px;
        }
    }


    @media only screen and (max-width: 768px) {
        .container-footer {
            background-color:#F7F7F7;
        }


    .content-footer {
        flex-direction: column;
        padding: 20px 0 120px 0;
        align-items: center;


        .content-links {
            width:90%;
            display:flex;
            flex-direction: column;
            & > div {


          span {
              position:absolute;
              bottom: -70px;
              font-size:12px;
          }
      }

        }



        a {
            margin-top: 10px;
        }
    }
    }




</style>
