<template>
    <div class="container-title">
      <span class="title-text">Ganhe <div>R${{value}}</div> com a {{brandName}}</span>
    </div>
</template>

<script>
export default {
    name: "ContentTitle",
    props: {
        value: {
            type: String,
        },
        brandName: {
            type: String,
        },
    },
};
</script>

<style lang="scss">
    .container-title {
        display:flex;
        flex-direction:column;
        align-items: center;
        height: 10vh;

        span {
            z-index: 99999;
            position:relative;

            div {
                background-image: url('../../../../public/img/background-money.png');
                background-size: cover;
                background-repeat: no-repeat;
                display: inline-block;
            }
        }

        img {
            position: absolute;
            width:180px;
            left: 160px;
        }

        & > .title-text {
            color: var(--Black, #101010);
            text-align: center;
            font-family: 'Silka Medium';
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;

            @media only screen and (max-width: 768px) {
              padding: 0 6%;
              font-size: 32px;
              line-height: 44px;
            }
        }
    }

    @media only screen and (max-width: 768px) {

        .container-title {
            display:flex;
            flex-direction:column;
            align-items: center;
            margin-top: 20px;
            height: auto;

            img {
                width:110px;
                left: 140px;
            }

        & > .title-text {
            font-size: 32px;
        }
    }
    }
</style>
