<template>
  <div class="container">
    <span class="title">Ops! Seu navegador está em modo privado</span>
    <span class="subtitle">Para ser compatível com o app Modatta, copie o link abaixo e cole em seu navegador em modo <strong>não</strong> privado.</span>
    <div class="button-custom" style="background: var(--Yellow, #F8CD60);" @click="onCopyLink">Copiar link</div>
    <span class="alert"><strong>Importante:</strong> Dúvidas sobre como abrir uma aba não privada <strong>(não anônima)</strong>, entre em contato com o nosso atendimento.</span>
    <div class="button-custom" style="background: var(--Primary, #30D6BB);" @click="onTalkToUs">Falar conosco</div>
  </div>
</template>

<script>
  export default {
    name: "PrivateBrowser",
    props: {
      onCopyLink: Function,
      onTalkToUs: Function
    },
  };
</script>

<style lang="scss" scoped>
  .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 70%;

      .title {
        color: var(--Black, #101010);
        font-family: 'Silka Medium';
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.2px;
        line-height: 140%;
        margin-bottom: 2%;
      }

      .subtitle {
        color: var(--Black, #101010);
        text-align: center;
        font-family: 'Silka Medium';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.2px;
        line-height: 140%;
        margin-top: 2%;
      }

      .alert {
        color: var(--Black, #101010);
        text-align: center;
        font-family: 'Silka Medium';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.2px;
        line-height: 140%;
        margin-bottom: 2%;
        margin-top: 4%;

        strong {
          color: hsl(0, 0%, 21%);
          font-weight: 600;
        }
      }

      .button-custom {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-bottom: 2%;
        border: 1px solid var(--Light-grey-surface, #F7F7F7);
        border-radius: 12px;
        width: 40%;
        height: 40px;
        cursor: pointer;
      }
  }

@media only screen and (max-width: 768px) {
  .container {
    width: 92%;
      .title {
        font-size: 28px;
      }

      .subtitle {
        font-size: 16px;
      }

      .button-custom {
        width: 80%;
      }
  }
}
</style>
