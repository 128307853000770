<template>
  <div class="container">
    <span class="title">Aguarde um instante!</span>
    <span class="subtitle">Testando a compatibilidade do seu navegador.</span>
    <div id="answer"><br />
        <div class="loader"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Loading",
  };
</script>

<style lang="scss" scoped>
  .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 70%;

      .title {
        color: var(--Black, #101010);
        font-family: 'Silka Medium';
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.2px;
        line-height: 140%;
      }

      .subtitle {
        color: var(--Black, #101010);
        text-align: center;
        font-family: 'Silka Medium';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.2px;
        line-height: 140%;
      }

      .loader {
        border: 5px solid var(--Light-grey-surface, #F7F7F7);
        border-top: 5px solid var(--Yellow, #F8CD60);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
      }
  }

@media only screen and (max-width: 768px) {
  .container {
      .title {
        font-size: 28px;
      }

      .subtitle {
        width: 70%;
        font-size: 16px;
      }
  }
}
</style>