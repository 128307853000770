import Vue from "vue";
import i18n from "./i18n";
import Buefy from "buefy";
import "./styles/app.scss";
import App from "./App.vue";
import router from "./router";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'

if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG,
    plugins: [new BugsnagPluginVue()]
  })
  BugsnagPerformance.start({ apiKey: process.env.VUE_APP_BUGSNAG })
  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)
}

Vue.use(Buefy);

// VUE INSTANCE
Vue.config.productionTip = false;
const files = require.context("./", true, /\.vue$/i);
files.keys().map((key) =>
  Vue.component(
    key
      .split("/")
      .pop()
      .split(".")[0],
    files(key).default
  )
);

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app");
