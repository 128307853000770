<template>
    <div class="container-description">
        <img class="line-2" alt="Line" src="../../../../public/img/face.svg" />
        <h2>{{brandName}}</h2>
        <h3 class="bold">{{ name }}</h3>
        <h3>{{ description }}</h3>
        <div class="align-center">
            <div class="container-buttons">
                <a class="primary-button" @click="onClick">
                    Quero começar
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentKnowHow",
    props: {
        onClick: {
          type: Function,
          required: true,
        },
        offerId: {
            type: String
        },
        brandName: {
            type: String,
        },
        description: {
            type: String,
        },
        name: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>

.primary-button-mobile {
    display:none;
}
    .container-description {
        padding: 36px 133px;
        width: 100%;
        flex:1;
        height: 50vh;
        .bold {
            font-weight: bold !important;
        }
        h3 {
            margin-top:0px !important;
            margin-bottom:0px !important;
        }
    }

    .align-center {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .container-buttons{
        display:flex;
        justify-content: space-between;
        flex-direction: row;
        width:95%;
        margin-top: 48px;
        align-content: center;
        .primary-button {
            border-radius: 12px;
            background: var(--Green, #19D2B4);
            width:100%;
            padding:16px;
            text-align: center;
            margin-left: 0px;
            font-weight: bold !important;
            line-height: 16.119px; /* 133.333% */
            color:black;
        }


    }

    .button-store {
        display:flex;
        cursor: pointer;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        border-radius: 6.448px;
        background: #FFF;
        box-shadow: 0px 10.02469px 75.18518px 0px rgba(0, 0, 0, 0.08);
        padding: 16px 16px;
        width:45%;


    }

    h2 {
        margin-top:32px;
    }

    @media only screen and (max-width: 768px) {

        .primary-button-mobile {
            margin-top:42px;
            display:block;
            border-radius: 12px;
            background: var(--Green, #19D2B4);
            width:100%;
            padding:16px;
            text-align: center;
            color: var(--Black, #101010);
            text-align: center;
            font-family: 'Silka Medium';
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 90% */
        }

        .container-description{
            padding: 0px 133px;

            & > h3{
                margin-top: 8px !important;
            }

            .align-center{
                display:none;
            }
        }

        .container-description {
            display:flex;
            padding:16px 28px;
            flex-direction:column;
            align-items:flex-start;

            &> img {
                width: 55px;
                margin-bottom:16px;
                height: 55px;
            }
        }

        h2 {
            font-size:28px !important;
            margin-top:0;
            margin-bottom:0 !important;
        }
    }

</style>
