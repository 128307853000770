<template>
  <div class="body">
    <Header></Header>
    <div class="step-mission-start">
      <span class="title">Pronto para começar a missão?</span>
      <span class="subtitle"
      >Para resgatar sua recompensa no app da Modatta, você precisará preencher as informações abaixo.</span
      >
      <div class="modatta-inner-container">
        <div class="modatta-phone f-75">
          <label>E-mail</label>
          <input
            id="input-email"
            placeholder="mail@exemplo.com.br"
            v-model="email"
            class="mission-input"
          />
          <label>Celular</label>
          <vue-tel-input
            autoFormat
            :defaultCountry="'br'"
            :onlyCountries="['br']"
            :inputOptions="inputOptions"
            @input="onInput"
          ></vue-tel-input>
          <button @click="handleButtonClick" class="modatta-button primary">
            <span>Começar a Missão</span>
          </button>
          <b-loading v-model="joinBtnLoading"></b-loading>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import CryptoJS from "crypto-js";
import { getInviteRef, subscribeOffer } from "../../api/offer";
import { getOffer } from "../../api/offer";

export default {
  components: { VueTelInput },
  data() {
    return {
      canDownload: false,
      inviteObject: {
        ref: this.$route.query.ref,
        valid: false,
      },
      email: "",
      phoneNumber: "",
      phoneObject: {
        number: "",
      },
      inputOptions: {
        placeholder: "(00) 98765-4321",
        required: true,
      },
      joinBtnLoading: false,
      offer: {
        id: "",
        name: "",
        description: "",
        action: "",
        country_id: "",
        currency_id: "",
        validation_max_hours: "",
        max_accepts: "",
        unit_value: "",
        perc_value: "",
        interaction_type_id: "",
        type_label_id: "",
        interaction_validation_id: "",
        banner: "",
        brand_id: "",
        recommendations_count: "",
        decisions_count: "",
        banner_url: "",
        brand: {
          id: "",
          name: "",
          brand_logo: "",
          company_url: "",
          logo_url: "",
        },
        steps: [
          {
            id: "",
            offer_id: "",
            description: "",
            index: "",
            created_at: "",
            updated_at: "",
          },
        ],
      },
    };
  },
  async mounted() {
    try {
      const result = await getOffer(this.offerId);
      this.offer = result.data;
      localStorage.removeItem('modatta-decision-tk');
    } catch (error) {
      this.$router.push(`/m/error`);
    }
  },
  props: {
    offerId: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const emailValidationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailValidationRegex.test(String(email).toLowerCase());
    },
    errorHandle(error) {
      if ([422, 406, 404].includes(error.response.status)) {
        this.$buefy.dialog.alert({
          title: this.$t("invite.alert.upswrong_title"),
          message: this.$t("invite.alert.upswrong_body"),
          confirmText: this.$t("invite.ok"),
        });
        this.canDownload = false;
      }
    },
    async handleButtonClick() {
      this.joinBtnLoading = true;
      if (!this.validateEmail(this.email)) {
        this.$buefy.dialog.alert({
          title: "Ops!",
          message: "Digite um e-mail válido",
          confirmText: this.$t("invite.ok"),
        });
        this.joinBtnLoading = false;
        return;
      }

      if (!this.phoneObject.valid) {
        this.$buefy.dialog.alert({
          title: "Ops!",
          message: "Digite um número de celular válido",
          confirmText: this.$t("invite.ok"),
        });
        this.joinBtnLoading = false;
        return;
      }

      try {
        const username = btoa(
          CryptoJS.SHA512(this.phoneObject.number.replace("+", ""))
        );
        const responseInviteRef = await getInviteRef(this.offerId, {
          email: this.email,
          phone: this.phoneObject.number,
          username,
        });

        const decisionTK = CryptoJS.SHA256(
          this.offerId + username + responseInviteRef.data?.invite_ref
        ).toString(CryptoJS.enc.Base32);

        try {
          await subscribeOffer(this.offerId, {
            username,
            decision_tk: decisionTK,
          });

          const surveyTypeMissionId = 'INT18';
          if (this.offer.interaction_type_id === surveyTypeMissionId) {
            localStorage.setItem('modatta-decision-tk', decisionTK);
            this.$router.push(`/m/q/${this.offerId}`);
          } else {
            window.location.href = this.offer.action.replace("{modid}", decisionTK);
          }
        } catch (error) {
          this.$buefy.dialog.alert({
            title: "Ops!",
            message: "Parece que você já fez essa missão. Fique atento para as próximas que vamos preparar para você!",
            confirmText: this.$t("invite.ok"),
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Ops!",
          message: "Ocorreu um erro, tente novamente mais tarde.",
          confirmText: this.$t("invite.ok"),
        });
      } finally {
        this.joinBtnLoading = false;
      }
    },
    onInput(number, phoneObject) {
      this.phoneObject = phoneObject;
    },
  },
};
</script>

<style lang="scss">
.body {
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modatta-button {
  margin-top: 40px !important;
}

.mission-input {
  margin-bottom: 16px !important;
  width: 100% !important;
}

label {
  margin-bottom: 8px;
  display: inline-block;
  color: var(--Black, #101010);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Silka Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 178.571% */
  letter-spacing: 0.3px;
}

.vue-tel-input,
.mission-input {
  border-radius: 8px !important;
  border: 1px solid var(--Medium-grey, #e0e0e0) !important;
  background: #f9fafb !important;
}

.step-mission-start {
  input {
    font-size: 16px !important;
    min-width: 400px;
  }

  #input-email {
    padding: 0 0 0 30px !important; // Add your desired padding here
  }

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 70%;

  img {
    border-radius: 20px;
    width: 50%;
    height: 80%;
  }

  span {
    text-align: center;
  }

  .title {
    color: var(--Black, #101010);
    text-align: center;
    font-family: "Silka Medium";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 44.8px */
    margin-bottom: 45px;
  }

  .subtitle {
    color: var(--Black, #101010);
    text-align: center;
    width: 40%;
    font-family: "Silka Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .step-mission-start {
    margin: 40px 20px 0 20px;

    input {
      font-size: 16px !important;
      min-width: 200px;
    }

    img {
      border-radius: 20px;
      width: 80%;
      height: 100%;
    }

    .title {
      color: var(--Black, #101010);
      text-align: center;
      font-family: "Silka Medium";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 44.8px */
    }

    .subtitle {
      color: var(--Black, #101010);
      text-align: center;
      width: 90%;
      font-family: "Silka Medium";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
    }
  }
}
</style>
