<template>
  <div class="checkbox-answer-container" v-show="this.questionData.element_type_id === this.checkboxTypeSurveyElementId">
      <span class="checkbox-question">{{ this.questionData.question }}</span>
      <div
        v-for="(answerData, index) in this.questionData.offer_answers"
        :class="{'checkbox-answer-item': true, 'checkbox-answer-item-active': isSelectedAnswer(index)}"
        :key="index"
        @click="handleSelectAnswer(index)">
        <input class="checkbox-answer-value" type="checkbox">
        <span class="checkbox-answer-label">{{ answerData.answer }}</span>
      </div>
  </div>
</template>

<script>
  export default {
    props: {
      questionData: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        checkboxTypeSurveyElementId: 'ELT1',
        selectedAnswersIndex: []
      };
    },
    watch: {
      selectedAnswersIndex(selectedList) {
        const answersSelected = selectedList.map(selected => {
          const answer = this.questionData.offer_answers[selected]
          return {
            question_id: `QUE${this.questionData.id}`,
            answer_id: `ANS${answer.id}`,
            answer: answer.answer
          }
        });
        this.$emit('checkbox-answer-question', answersSelected);
      }
    },
    methods: {
      handleSelectAnswer(index) {
        const isSelected = this.selectedAnswersIndex.includes(index);
        if (isSelected) {
          this.selectedAnswersIndex = this.selectedAnswersIndex.filter(item => item !== index);
        } else {
          this.selectedAnswersIndex.push(index);
        }
      },
      isSelectedAnswer(index) {
        return this.selectedAnswersIndex.includes(index);
      }
    }
  };
</script>


<style lang="scss" scoped>
  .checkbox-answer-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .checkbox-question {
      font-family: "Silka Medium", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      margin-bottom: 4%;
      color: #101010;
    }

    .checkbox-answer {
      width: 100%;
      height: 36vh !important;
      border: 1px solid #E0E0E0;
      border-radius: 8px;
      background-color: #F9FAFB;
      font-size: 16px !important;
      padding: 2% 2%;
      resize: none !important;
    }

    .checkbox-answer-item {
      display: flex;
      flex-direction: row;
      width: 100%;
      background-color: #F3F4F5;
      border: 1px solid #D9D9D9;
      padding: 18px 12px;
      margin-bottom: 12px;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        .checkbox-answer-value {
          box-shadow: 0 0 0 6px #19D2B433;
        }
      }

      .checkbox-answer-label {
        font-family: "Silka Medium", sans-serif;
        font-size: 16px;
        line-height: 18px;
        color: #101010;
        margin-left: 12px;
      }

      .checkbox-answer-value {
        border-radius: 4px;
        border: 1px solid #D7D7D7;
        width: 18px;
        height: 18px;
      }
    }

    .checkbox-answer-item-active {
      background-color: #FFFFFF;
      border: 1px solid #19D2B4;

      .checkbox-answer-value {
        background-color: #19D2B4;
        border: 4px solid #19D2B433;
      }
    }
  }

</style>
