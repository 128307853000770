<template>
    <div v-if="!loading" class="container-mission">
        <Header></Header>
        <ContentTitle :value="this.offer.unit_value" :brandName="this.offer.brand.name"/>
        <div class="main-content">
            <ContentDescription
              :brandName="this.offer.brand.name"
              :name="this.offer.name"
              :offerId="offerId"
              :description="this.offer.description"
              :on-click="this.goToStartMission"
            />
            <ContentImage
              :availables="this.offer.availables"
              :paymentDays="this.offer.payment_days"
              :validationMaxHours="this.offer.validation_max_hours" :bannerUrl="this.offer.banner_url"
            />
        </div>
        <ContentKnowHow
          :on-click="this.goToStartMission"
          :offerId="offerId"
        />
        <ContentWhatModatta
          :on-click="this.goToStartMission"
          :offerId="offerId"
        />
        <Footer></Footer>
        <div class="button-container">
            <a class="primary-button" @click="this.goToStartMission">
                Quero começar
            </a>
        </div>
    </div>
</template>

<script>
import { getOffer } from '../../api/offer';

export default {
    data() {
        return {
            loading: true,
            offer: {
                id: "",
                name: "",
                description: "",
                action: "",
                country_id: "",
                currency_id: "",
                availables: "",
                validation_max_hours: "",
                max_accepts: "",
                unit_value: "",
                perc_value: "",
                interaction_type_id: "",
                type_label_id: "",
                interaction_validation_id: "",
                banner: "",
                brand_id: "",
                recommendations_count: "",
                decisions_count: "",
                banner_url: "",
                brand: {
                    id: "",
                    name: "",
                    brand_logo: "",
                    company_url: "",
                    logo_url: ""
                },
                steps: [
                    {
                        id: "",
                        offer_id: "",
                        description: "",
                        index: "",
                        created_at: "",
                        updated_at: ""
                    }
                ]
            }
       };
    },
    props: {
        offerId: {
            type: String,
            required: true
        }
    },
    async mounted() {
        try {
            const result = await getOffer(this.offerId);
            this.offer = result.data;
        } catch (error) {
            this.$router.push(`/m/error`);
        } finally {
            this.loading = false;
        }
    },
    methods: {
      goToStartMission() {
        const researchTypeMissionId = 'INT18';
        if (this.offer.interaction_type_id === researchTypeMissionId) {
          this.$router.push(`/m/start/${this.offerId}`);
        } else {
          this.$router.push(`/m/s/${this.offerId}`);
        }
      }
    },
};
</script>

<style lang="scss" scoped>


    .button-container {
        display:none;
    }

    .container-mission{
        background-color:#FFF;
    }

    .body {
        flex:1;
        width: 100%;
        background-color:red !important;

    }

    .main-content {
        display: grid;
        grid-template-columns: 2fr 3fr;
        height: 68vh;
    }



    @media only screen and (max-width: 768px) {


        .container-mission{
        background-color:#FFF;
        margin-bottom:40px;
    }
        .primary-button {
            border-radius: 12px;
            background: var(--Green, #19D2B4);
            width:100%;
            padding:16px;
            text-align: center;
            margin: 0 16px;
            font-weight: bold !important;
            line-height: 16.119px; /* 133.333% */
            color:black;
}
.button-container {
        position: fixed;
        bottom:0;
        width:100%;
        display:flex;
        background-color:white;
        padding-top:20px;
        align-items: center;
        align-content: center;
        padding-bottom:20px;
    }


        .main-content {
        display:flex;
        flex-direction: column-reverse;
        height: auto;
    }
    }
</style>
