<template>
  <div class="body">
    <Header></Header>
    <div class="container-loading" v-show="isLoading">
      <span class="loading"></span>
    </div>
    <main class="container" v-show="!isLoading">
      <div class="container-question">
        <short-answer-type-question :question-data="this.questionAction" @short-answer-question="getShortAnswerQuestion" />
        <long-answer-type-question :question-data="this.questionAction" @long-answer-question="getlongAnswerQuestion"/>
        <radio-answer-type-question :question-data="this.questionAction" @radio-answer-question="getRadioAnswerQuestion"/>
        <dropbox-answer-type-question :question-data="this.questionAction" @dropbox-answer-question="getDropboxAnswerQuestion"/>
        <checkbox-answer-type-question :question-data="this.questionAction" @checkbox-answer-question="getCheckboxAnswerQuestion"/>
      </div>
      <span class="label">({{`${this.questionActionNumble}/${this.totalQuestions}`}})</span>
      <div class="progress-bar" >
        <span
          v-for="(item, index) in totalQuestions"
          :key="index"
          :class="{'bar': true, 'active': item <= questionActionNumble}">
        </span>
      </div>
      <div class="container-buttons-nativations">
        <button
          v-bind:disabled="this.questionActionNumble === 1"
          @click="this.handleBackQuestion"
          class="button">
          Voltar
        </button>
        <button
          v-bind:disabled="!this.allowedToGoToTheNextQuestion"
          @click="this.handleNextQuestion"
          class="button">
          {{ this.questionActionNumble === this.totalQuestions ? 'Finalizar' : 'Continuar' }}
        </button>
      </div>
    </main>
    <Footer class="mission-survery-footer"></Footer>
  </div>
</template>

<script>
  import ShortAnswerTypeQuestion from "./components/ShortAnswerTypeQuestion.vue";
  import LongAnswerTypeQuestion from "./components/LongAnswerTypeQuestion.vue";
  import RadioAnswerTypeQuestion from "./components/RadioAnswerTypeQuestion.vue";
  import { getSurvey, setSurvey } from '../../api/offer';
  import { sendEventTargetMission } from '../../api/analytics';

  export default {
    components: { ShortAnswerTypeQuestion, LongAnswerTypeQuestion, RadioAnswerTypeQuestion },
    props: {
      offerId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        surveyQuestions: [],
        questionAction: {},
        questionActionNumble: 1,
        isLoading: true,
        dataShortAnswerQuestion: null,
        dataLongAnswerQuestion: null,
        dataRadioAnswerQuestion: null,
        dataDropboxAnswerQuestion: null,
        dataCheckboxAnswerQuestion: null,
      };
    },
    async mounted() {
      try {
        const response = await getSurvey(this.offerId)
        this.surveyQuestions = response.data;
        this.questionAction = this.surveyQuestions[0]
      } catch (error) {
        this.$router.push(`/m/error`);
      } finally {
        this.isLoading = false;
      }
    },
    methods: {
      async handleNextQuestion() {
        if (this.questionActionNumble === this.totalQuestions) {
          try {
            this.isLoading = true;
            await setSurvey(this.offerId, {
              decision_tk: localStorage.getItem('modatta-decision-tk'),
              answers_user: this.factoryUserAnswer()
            });
            await this.sendEventSuccess();
            this.$router.push('/m/success');
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Ops!",
              message: "Ocorreu um erro, tente novamente mais tarde.",
              confirmText: this.$t("invite.ok"),
            });
          } finally {
            this.isLoading = false;
          }
        } else if (this.questionActionNumble < this.totalQuestions) {
          this.questionActionNumble = this.questionActionNumble + 1;
          const questionActionResearch = this.questionActionNumble - 1;
          this.questionAction = this.surveyQuestions[questionActionResearch]
        }
      },
      handleBackQuestion() {
        if (this.questionActionNumble > 1) {
          this.questionActionNumble = this.questionActionNumble - 1;
          const questionActionResearch = this.questionActionNumble - 1;
          this.questionAction = this.surveyQuestions[questionActionResearch]
        }
      },
      factoryUserAnswer() {
        const userAnswerList = [];
        if (this.dataShortAnswerQuestion){
          userAnswerList.push(this.dataShortAnswerQuestion)
        }
        if (this.dataLongAnswerQuestion){
          userAnswerList.push(this.dataLongAnswerQuestion)
        }
        if (this.dataRadioAnswerQuestion){
          userAnswerList.push(this.dataRadioAnswerQuestion)
        }
        if (this.dataDropboxAnswerQuestion){
          userAnswerList.push(this.dataDropboxAnswerQuestion)
        }
        if (this.dataCheckboxAnswerQuestion){
          userAnswerList.push(this.dataCheckboxAnswerQuestion)
        }
        return userAnswerList;
      },
      getShortAnswerQuestion(answer) {
        this.dataShortAnswerQuestion = answer;
      },
      getlongAnswerQuestion(answer) {
        this.dataLongAnswerQuestion = answer;
      },
      getRadioAnswerQuestion(answer) {
        this.dataRadioAnswerQuestion = answer;
      },
      getDropboxAnswerQuestion(answer) {
        this.dataDropboxAnswerQuestion = answer;
      },
      getCheckboxAnswerQuestion(answer) {
        this.dataCheckboxAnswerQuestion = answer;
      },
      async sendEventSuccess() {
        const surveyResultList = [];
        if (this.dataShortAnswerQuestion){
          surveyResultList.push({
            question_id: this.dataShortAnswerQuestion.question_id,
            answer_list: [
              {
                value: this.dataShortAnswerQuestion.answer
              }
            ]
          })
        }
        if (this.dataLongAnswerQuestion){
          surveyResultList.push({
            question_id: this.dataLongAnswerQuestion.question_id,
            answer_list: [
              {
                value: this.dataLongAnswerQuestion.answer
              }
            ]
          })
        }
        if (this.dataRadioAnswerQuestion){
          surveyResultList.push({
            question_id: this.dataRadioAnswerQuestion.question_id,
            answer_list: [
              {
                id: this.dataRadioAnswerQuestion.answer_id
              }
            ]
          })
        }
        if (this.dataDropboxAnswerQuestion){
          surveyResultList.push({
            question_id: this.dataDropboxAnswerQuestion.question_id,
            answer_list: [
              {
                id: this.dataDropboxAnswerQuestion.answer_id
              }
            ]
          })
        }
        if (this.dataCheckboxAnswerQuestion) {
          const answerList = this.dataCheckboxAnswerQuestion.map(question => {
            return {
              id: question.answer_id
            }
          });
          surveyResultList.push({
            question_id: this.dataCheckboxAnswerQuestion[0].question_id,
            answer_list: answerList
          })
        }
        await sendEventTargetMission({
          timestamp: Date.now(),
          user_id: null,
          device: {
            os_name: navigator.userAgent,
          },
          event_type: "SURVEY_ANSWER",
          resource_type: "SURVEY",
          app_page: "Survey",
          username: null,
          push_tk: null,
          app_version: null,
          resource: {
            modid: localStorage.getItem('modatta-decision-tk'),
            offer_id: this.offerId,
            survey_result_list: surveyResultList
          },
        });
      }
    },
    computed: {
      totalQuestions() {
        return this.surveyQuestions.length;
      },
      allowedToGoToTheNextQuestion() {
        if (this.questionAction.element_type_id === 'ELT1' && this.dataCheckboxAnswerQuestion) {
          return true;
        }
        if (this.questionAction.element_type_id === 'ELT2' && this.dataRadioAnswerQuestion) {
          return true;
        }
        if (this.questionAction.element_type_id === 'ELT6' && this.dataShortAnswerQuestion) {
          return true;
        }
        if (this.questionAction.element_type_id === 'ELT10' && this.dataDropboxAnswerQuestion) {
          return true;
        }
        if (this.questionAction.element_type_id === 'ELT12' && this.dataLongAnswerQuestion) {
          return true;
        }
        return false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .container-loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .loading {
    width: 64px;
    height: 64px;
    border: 4px solid #FFFFFF;
    border-bottom-color: #19d2b4;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .body {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100svh;
    width: 100%;
    background-color: #FFFFFF;

    .container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 4%;
      height: 70%;
      width: 70%;

      @media only screen and (max-width: 768px) {
        width: 90%;
      }

      .container-question {
        width: 70%;
        height: 100%;

        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .label {
        font-family: "Silka Medium", sans-serif !important;
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        text-align: center;
        margin-top: 1vh;
      }

      .progress-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .bar {
          flex: 1;
          border: 2px solid #F3F4F5;
          margin: 0 4px;
          border-radius: 2px;
        }

        .active {
          border: 2px solid #19d2b4;
        }
      }

      .container-buttons-nativations {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 2vh;
        margin-bottom: 2vh;

        .button {
          flex: 1;
          margin-right: 1% !important;
          font-family: "Silka Medium", sans-serif;
          text-align: center;
          border-radius: 12px;
          padding: 25px !important;
          background-color: #19d2b4 !important;
          color: #101010;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
        }
      }
    }

    .mission-survery-footer {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }

</style>
