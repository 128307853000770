<template>
    <div class="container-know-how">
        <h2>Saiba Como</h2>
            <div class="step">
                <span class="number">1</span>
                <span class="description">Siga o passo a passo da missão.</span>
            </div>
            <div class="step">
                <span class="number">2</span>
                <span class="description">Após completar a missão, baixe o app da Modatta.</span>
            </div>
            <div class="step">
                <span class="number">3</span>
                <span class="description">No app, crie sua conta com mesmo email e celular desta missão para ver sua recompensa.</span>
            </div>
            <div class="button-container">
                <a class="primary-button" @click="onClick">
                    Quero começar
                </a>
            </div>
        </div>
</template>

<script>
export default {
    name: "ContentKnowHow",
    props: {
        onClick: {
          type: Function,
          required: true,
        },
        offerId: {
            type: String
        }
    }
};
</script>

<style lang="scss" scoped>

.primary-button {
    border-radius: 12px;
            background: var(--Green, #19D2B4);
            width:100%;
            padding:16px;
            text-align: center;
            margin-left: 16px;
            font-weight: bold !important;
            line-height: 16.119px; /* 133.333% */
            color:black;
}

.container-know-how {
    background: linear-gradient(180deg, rgba(247, 247, 248, 0.00) 0%, #F7F7F8 56.77%);
    flex:1;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;

        h2 {
            margin-bottom: 44px !important;
        }
    }



    .step {
        width:50%;
        padding: 32px 32px;
        background-color:#FFF;
        border-radius: 24px;
        display:flex;
        flex-direction: row;
        align-items: center;
        margin-bottom:12px;
        filter: drop-shadow(0px 2px 30px rgba(0, 0, 0, 0.05));


        .number {
            background-color: #FFCD46;
            padding:16px;
            min-width:0;
            border-radius: 80px;
        }

        span {
            color: var(--Black, #101010);
            text-align: left;
            font-family: 'Silka Regular';
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30.509px; /* 113.596% */
        }
    }


    .button-container {
        margin-top:60px;
        width:25%;
        display:flex;
        align-items: center;
        align-content: center;
        margin-bottom:90px;
    }

    @media only screen and (max-width: 768px) {

        .button-container {
            display:none;
        margin-top:30px;
        width:90%;
        margin-bottom:50px;
    }
    .container-know-how {
        margin-top: 40px;
        padding-bottom: 20px;
    }

    .step {
        flex-direction: column;
        justify-content: center;
        padding: 32px 16px;
        margin-bottom:24px;


        .number {
            margin:0;
            width:60px;
            height: 60px;
            font-size:26px;
            font-weight: bold;
            margin-bottom:12px;
        }


    span {
        color: var(--Black, #101010);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 22.4px */
        }
    }
}




</style>
