<template>
    <div class="container-header">
        <img src="/img/invite/logo.png" alt="Modatta Logo" />
        <div class="content">
            <a target="_blank" href="https://www.modatta.com/">
                <img src="../../public/svg/globe.svg" alt="Globe" />
                <span>Site</span></a>
            <a target="_blank" href="https://www.modatta.com/legal">
                <img src="../../public/svg/shield.svg" alt="shield" />
                <span>Política de privacidade</span></a>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
};
</script>

<style lang="scss">
    .container-header {
        display:flex;
        flex-direction:row;
        align-items: center;
        justify-content: space-between;
        padding: 36px 133px;
        & > img {
            width:173px;
            height: 33px;
        }
    }

    .content {
        display:flex;
        flex-direction: row;
        align-items: center;

        & > a {
            display:flex;
            flex-direction:row;
            align-items: center;
            justify-content: center;
            align-content: center;

            img {
                width:22px;
                height:22px;
            }

            span {
                margin-top:4px;
                margin-right: 24px;
                margin-left: 4px;
                color: var(--Black, #101010);
                font-family: 'Silka Medium';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 14.733px; /* 92.081% */
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .container-header {
        display:flex;
        flex-direction:row;
        align-items: center;
        justify-content: space-between;
        padding: 36px 23px;
        & > img {
            width:173px;
            height: 33px;
        }
    }

    .content {
        display:flex;
        flex-direction: row;
        align-items: center;

        a {
            margin-left:10px;
        }

        
            span {
               display:none;
            }

        & > div:first-child {
            img {
                width:26px;
                height:26px;
            }
        }

        & > div:nth-child(2) {
            img {
                width:29px;
                height:29px;
            }
        }
    }
    }
    
</style>