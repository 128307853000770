import Vue from "vue";
import VueRouter from "vue-router";
import MissionView from "../views/Mission/MissionView.vue";
import MissionStep from "../views/MissionStep";
import MissionSuccessView from "../views/MissionSuccess/MissionSuccessView.vue";
import MissionError from "../views/MissionError";
import MissionStartView from "../views/MissionStart/MissionStartView.vue";
import MissionRedirect from "../views/MissionRedirect";
import MissionSurveyView from "../views/MissionSurvey/MissionSurveyView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/m/success",
    name: "MissionSuccess",
    component: MissionSuccessView,
    props: true,
    meta: { title: "Mission • Modatta" },
  },
  {
    path: "/m/error",
    name: "MissionError",
    component: MissionError,
    props: true,
    meta: { title: "Mission • Modatta" },
  },
  {
    path: "/m/:offerId",
    name: "Mission",
    component: MissionView,
    props: true,
    meta: { title: "Mission • Modatta" },
  },
  {
    path: "/m/s/:offerId",
    name: "MissionStep",
    component: MissionStep,
    props: true,
    meta: { title: "Mission • Modatta" },
  },
  {
    path: "/m/start/:offerId",
    name: "MissionStart",
    component: MissionStartView,
    props: true,
    meta: { title: "Mission • Modatta" },
  },
  {
    path: "/redirect",
    name: "MissionRedirect",
    component: MissionRedirect,
    props: true,
    meta: { title: "Mission • Modatta" },
  },
  {
    path: "/m/q/:offerId",
    name: "MissionSurvey",
    component: MissionSurveyView,
    props: true,
    meta: { title: "Mission • Modatta" },
  }
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 };
  },
});

export default router;
