<template>
    <div class="body">
        <Header></Header>
        <div class="container">
            <div class="container-check">
              <img src="/img/survey_success.png" alt="Icone de check" />
            </div>
            <span class="title">Arrasou!</span>
            <span class="subtitle">A sua recompensa está te esperando!<br>Para aproveitar é só mais um pulo:</span>
            <div class="step">
              <span class="step-number">1</span>
              <span class="step-text">Baixe o aplicativo da Modatta.</span>
            </div>
            <div class="step">
              <span class="step-number">2</span>
              <span class="step-text">Crie a sua conta com os mesmos dados que usou para se cadastrar nesta missão.</span>
            </div>
            <div class="step">
              <span class="step-number">3</span>
              <span class="step-text">Prontinho! Seu saldo estará te esperando junto com muitas outras missões e recompensas.</span>
            </div>
            <div class="container-store">
              <div class="button-store" @click="goToPlayStore">
                <img src="/svg/google-play-store-icon.svg" alt="Apple Store Logo" />
                <span>Play Store</span>
              </div>
              <div class="button-store" @click="goToAppleStore">
                <img src="/svg/apple-app-store-icon.svg" alt="Apple Store Logo" />
                <span>Apple Store</span>
              </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
  export default {
    methods: {
      goToPlayStore() {
        window.open(process.env.VUE_APP_GOOGLE_STORE, "_blank");
      },
      goToAppleStore() {
        window.open(process.env.VUE_APP_GOOGLE_STORE, "_blank");
      }
    }
  }
</script>


<style lang="scss">
  .body {
    flex:1;
    height: 100vh;
    display:flex;
    flex-direction: column;
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;

    @media only screen and (max-width: 768px) {
      width: 90vw;
    }

    .container-check {
      display: flex;
      justify-content: center;
      width: 28%;
      height: 28%;
      margin-bottom: 24px;

      @media only screen and (max-width: 768px) {
        width: 42%;
        height: 42%;
      }
    }

    .title {
      font-family: "Silka Medium", sans-serif !important;
      font-size: 32px;
      font-weight: bold;
      line-height: 44px;
      text-align: center;
      margin-bottom: 36px;
      color: #101010;

      @media only screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .subtitle {
      font-family: "Silka Medium", sans-serif !important;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      color: #101010;

      @media only screen and (max-width: 768px) {
        font-size: 15px;
        line-height: 21px;
      }
    }

    .step {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      background-color: #FFFFFF;
      box-shadow: 0px 2px 30px 0px #0000000D;
      border-radius: 12px;
      margin: 6px 0;
      width: 100%;

      .step-number {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFCD46;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        font-family: "Silka Medium", sans-serif !important;
        font-size: 21px;
        font-weight: 600;
        line-height: 24px;
        color: #101010;
        margin-right: 12px;

        @media only screen and (max-width: 768px) {
          width: 38px;
          height: 38px;
          border-radius: 50%;
        }
      }

      .step-text {
        display: flex;
        flex: 1;
        font-family: "Silka Medium", sans-serif !important;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: #101010;

        @media only screen and (max-width: 768px) {
          font-size: 15px;
          line-height: 21px;
        }
      }
    }

    .container-store {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      margin: 4% 0;

      @media only screen and (max-width: 768px) {
        margin: 6% 0;
      }

      .button-store {
        display: flex;
        flex: 1;
        background-color: #30D6BB33;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 6px;
        padding: 24px;
        border-radius: 12px;
        max-width: 164px;
        max-height: 64px;
        cursor: pointer;

        &:hover {
          border: 2px solid #30D6BB;
        }

        img {
          margin-right: 6px;
        }

        span {
          font-family: "Silka Medium", sans-serif !important;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          margin-left: 6px;
        }
      }
    }
  }
</style>
