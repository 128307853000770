<template>
  <div class="step-video-container">
    <span class="title"
      >Saiba como ({{ this.index + 1 }}/{{ this.stepLength }})</span
    >
    <span class="subtitle">{{ step.description }}</span>
    <video
      muted
      controls="false"
      controlslist="nodownload noremoteplayback noplaybackrate foobar noplaysinline"
      disablepictureinpicture
      autoplay
      preload="metadata"
      @seeked="seeked"
      @timeupdate="changeTime">
        <source :src="step.media_source" type="video/mp4"/>
    </video>
  </div>
</template>

<script>
export default {
  name: "StepVideo",
  mounted() {
    this.setVideoEnded(false);
  },
  props: {
    index: Number,
    stepLength: Number,
    setVideoEnded: {
      type: Function,
      required: true,
    },
    setSecondsRemainingVideo: {
      type: Function,
      required: true,
    },
    step: {
      id: String,
      offer_id: String,
      description: String,
      index: String,
      created_at: String,
      updated_at: String,
      media_source: String,
      media_type_id: String,
    },
  },
  methods: {
    changeTime(data) {
      const secondsRemaining = parseInt((data.srcElement.duration - data.srcElement.currentTime).toFixed(0));
      this.setSecondsRemainingVideo(secondsRemaining);
    },
    seeked(data) {
      if (data.srcElement.currentTime < data.srcElement.duration) {
        this.$buefy.dialog.alert({
          title: "OPS!",
          message: "Você precisa ver o video até o final.",
          confirmText: "Assistir o video novamente",
          onConfirm: () => {
            data.srcElement.load();
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vimeo {
  width: 35%;
  min-width: 35%;
  height: 100%;
  position: relative;
}

.vimeo-portrait {
  width: 15%;
  height: 100%;
  position: relative;
}

.step-video-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: 50%;

  span {
    text-align: center;
  }

  .title {
    color: var(--Black, #101010);
    text-align: center;
    font-family: "Silka Medium";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 44.8px */
  }

  .subtitle {
    color: var(--Black, #101010);
    text-align: center;
    width: 50%;
    font-family: "Silka Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
  }
}

@media only screen and (max-width: 768px) {
  .vimeo {
    left: 0;
    top: 0;
    height: 30%;
    width: 100%;
    margin-top: 50px;
    position: relative;
  }

  .step-video-container {
    height: 45vh;

    .title {
      color: var(--Black, #101010);
      text-align: center;
      font-family: "Silka Medium";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    .subtitle {
      color: var(--Black, #101010);
      text-align: center;
      width: 70%;
      font-family: "Silka Medium";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
}
</style>
