import axios from "axios";

export async function getOffer(id) {
  const URL = `${process.env.VUE_APP_API_URL}external/landings/offers/${id}`;
  return await axios.get(URL);
}

export async function getSurvey(offerId) {
  return await axios.get(`${process.env.VUE_APP_API_V2_URL}webapp/offers/${offerId}/survey`);
}

export async function setSurvey(offerId, body) {
  return await axios.post(`${process.env.VUE_APP_API_V2_URL}webapp/offers/${offerId}/survey`, body);
}

export async function getInviteRef(offerId, body) {
  const URL = `${process.env.VUE_APP_API_URL}external/landings/offers/${offerId}/user`;
  return await axios.post(URL, body);
}

export async function subscribeOffer(offerId, body) {
  const URL = `${process.env.VUE_APP_API_URL}external/landings/offers/${offerId}`;
  return await axios.post(URL, body);
}
