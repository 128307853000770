<template>
    <div class="step-video-container">
        <span class="title">Ouça o Audio</span>
        <span class="subtitle">Ouça este aúdio que lhe vai explicar os proximos passos, desde dar o consentimento de dados a ter de aguardar pela confiramção e o premio será seu.</span>
    </div>
</template>

<script>
export default {
    name: "StepVideo",
};
</script>

<style lang="scss" scoped>
    .step-video-container {
        display:flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 50%;

        img {
            border-radius:20px;
            width: 50%;
            height: 80%;
        }
        
        span {
            text-align: center;
        }

        .title {
            color: var(--Black, #101010);
            text-align: center;
            font-family: 'Silka Medium';
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 44.8px */
        }

        .subtitle {
            color: var(--Black, #101010);
            text-align: center;
            width:50%;
            font-family: 'Silka Medium';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
        }
    }

@media only screen and (max-width: 768px) {
    .step-video-container {
        
        img {
            border-radius:20px;
            width:80%;
            height: 100%;
        }

        .title {
            color: var(--Black, #101010);
            text-align: center;
            font-family: 'Silka Medium';
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 44.8px */
        }

        .subtitle {
            color: var(--Black, #101010);
            text-align: center;
            width:70%;
            font-family: 'Silka Medium';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
        }
    }

}
</style>