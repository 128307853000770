<template>
  <div class="step-buttons-container">
    <span>({{ step }}/{{ stepLength }})</span>
    <div class="bar-container">
      <div
        v-for="(item, index) in steps"
        :key="index"
        class="bar"
        :style="{ backgroundColor: step > index ? '#19D2B4' : '#DDD' }"
      ></div>
    </div>
    <button
      v-if="actualMediaTypeId !== 'MTY3'"
      @click="handleButtonClick"
      :disabled="buttonDisabled"
      class="button"
    >
      {{ countdown > 0 ? countdown : "Continue" }}
    </button>
    <button
      v-if="actualMediaTypeId === 'MTY3'"
      @click="handleButtonClick"
      :disabled="secondsRemainingVideo > 0"
      class="button"
    >
      {{
        secondsRemainingVideo === 0
          ? "Continue"
          : secondsRemainingVideo
      }}
    </button>
  </div>
</template>

<script>
export default {
  name: "StepButtons",
  data() {
    return {
      buttonDisabled: true,
      countdown: 0,
    };
  },
  props: {
    steps: {
      type: Array,
    },
    index: Number,
    actualMediaTypeId: String,
    stepLength: Number,
    secondsRemainingVideo: Number,
    incrementStep: {
      type: Function,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    videoEnded: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.countdown = 5;

    setTimeout(() => {
      this.buttonDisabled = false;
    }, 5000);

    let intervalId = setInterval(() => {
      if (this.countdown > 1) {
        this.countdown--;
      } else {
        clearInterval(intervalId);
        this.countdown = 0;
      }
    }, 1000);
  },
  methods: {
    handleButtonClick() {
      // Disable the button
      this.buttonDisabled = true;
      this.countdown = 5;

      // Perform the desired action (e.g., incrementStep)
      this.incrementStep();

      // Enable the button after a delay (e.g., 2000 milliseconds or 2 seconds)
      setTimeout(() => {
        this.buttonDisabled = false;
      }, 5000);

      let intervalId = setInterval(() => {
        if (this.countdown > 1) {
          this.countdown--;
        } else {
          clearInterval(intervalId);
          this.countdown = 0;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.step-buttons-container {
  margin: 40px 0px;
  .bar-container {
    width: 20%;
    height: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 14px 0px 14px 0px;

    .bar {
      background: #19d2b4;
      width: 30%;
      height: 4px;
      border-radius: 16px;
    }
  }

  span {
    color: var(--Black, #101010);
    text-align: center;
    font-family: "Silka Medium";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px; /* 86.667% */
  }

  .button {
    border-radius: 12px;
    width: 300px !important;
    background: #19d2b4 !important;
    margin-top: 10px !important;
    color: var(--Black, #101010);
    text-align: center;
    font-family: "Silka Medium";
    padding: 25px !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 100% */
  }

  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .step-buttons-container {
    margin: 40px 0px;
    .bar-container {
      width: 60%;
    }
  }
}
</style>
