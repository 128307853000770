<template>
  <div class="dropbox-answer-container" v-show="this.questionData.element_type_id === this.dropboxTypeSurveyElementId">
      <span class="dropbox-question">{{ this.questionData.question }}</span>
      <select class="dropbox-answer" v-model="userAnswer">
        <option disabled value="">Por favor, escolha uma opção</option>
        <option :key="index" v-for="(answerData, index) in this.questionData.offer_answers" :value="answerData">
          {{ answerData.answer }}
        </option>
      </select>
  </div>
</template>

<script>
  export default {
    props: {
      questionData: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        dropboxTypeSurveyElementId: 'ELT10',
        userAnswer: null,
      };
    },
    watch: {
      userAnswer(value) {
        this.$emit('dropbox-answer-question', {
          question_id: `QUE${this.questionData.id}`,
          answer_id: `ANS${value.id}`,
          answer: value.answer
        });
      }
    }
  };
</script>


<style lang="scss" scoped>
  .dropbox-answer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .dropbox-question {
    font-family: "Silka Medium", sans-serif !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-bottom: 4%;
    color: #101010;
  }

  .dropbox-answer {
    width: 100%;
    height: 60px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    background-color: #F9FAFB;
    font-size: 16px !important;
    padding: 0 8px;
  }
</style>
